// theme constant
import { toast } from 'react-toastify';

export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
// export const baseURL = 'http://128.199.27.141:3000';
export const baseURL = 'http://159.65.144.80:3000';
// export const baseURL = 'http://localhost:3000';

export const showToastSuccess = (msg) => {
    toast(
        <div className="u_toast-inner u_toast-inner-success">
            <div className="u_flex u_align-center">
                {/* <SuccessIcon/> */}
                <div className="u_pl-15">
                    <h2 style={{ color: 'green' }}>Successful!</h2>
                    <p>{msg}</p>
                </div>
            </div>
        </div>,
        {
            className: 'u_toast',
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeButton: false
        }
    );
};
export const showToastError = (msg) => {
    toast(
        <div className="u_toast-inner u_toast-inner-error">
            <div className="u_flex u_align-center">
                {/* <SuccessIcon/> */}
                <div className="u_pl-15">
                    <h2 style={{ color: 'red' }}>Error!</h2>
                    <p>{msg}</p>
                </div>
            </div>
        </div>,
        {
            className: 'u_toast',
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeButton: false
        }
    );
};

export const getToken = () => {
    return localStorage?.getItem('accessToken');
};

export const CourierOption = [
    { value: 'delhivery_surface', label: 'DELHIVERY_SURFACE' },
    { value: 'delhivery_air', label: 'DELHIVERY_AIR' },
    { value: 'ecom_express_air', label: 'ECOM_EXPRESS_AIR' },
    { value: 'ecom_express_surface', label: 'ECOM_EXPRESS_SURFACE' },
    { value: 'fedex_surface', label: 'FEDEX_SURFACE' },
    { value: 'fedex_air', label: 'FEDEX_AIR' },
    { value: 'dtdc', label: 'DTDC' },
    { value: 'xpressbees', label: 'XPRESSBEES' },
    { value: 'ecart_air', label: 'ECART_AIR' },
    { value: 'ecart_surface', label: 'ECART_SURFACE' },
    { value: 'bluedart_air', label: 'BLUEDART_AIR' },
    { value: 'bluedart_surface', label: 'BLUEDART_SURFACE' },
    { value: 'shadow_fax', label: 'SHADOW_FAX' }
];

export const ORDER_TYPE = {
    COD: 1,
    PREPAID: 2
};
export const ORDER_STATUS = {
    SHORT_ADDRESS: 1,
    BIG_AMOUNT: 2,
    DUPLICATE: 3,
    NOT_RECIEVED_CALL1: 5,
    NOT_RECIEVED_CALL2: 6,
    NOT_RECIEVED_CALL3: 7,
    DONE_ORDER: 4,
    CANCEL_ORDER: 8,
    NOT_PICKED: 9,
    LIVE: 10,
    DELIVERED: 11,
    RETURN: 12,
    RETURN_DELIVERED: 13,
    RETURN_RECEIVED: 14,
    WRONG_RETURN_RECEIVED: 15,
    CLAIM_SHIPMENT: 16,
    COMPLETED: 17,
    NO_SERVICE: 18 // No Service When Pin code service not available
};

export const orderStatus = [
    'UNKNOWN',
    'SHORT ADDRESS',
    'BIG AMOUNT',
    'DUPLICATE',
    'NOT RECIEVED CALL1',
    'NOT RECIEVED CALL2',
    'NOT RECIEVED CALL3',
    'DONE ORDER',
    'CANCEL ORDER',
    'NOT PICKED',
    'LIVE',
    'DELIVERED',
    'RETURN',
    'RETURN DELIVERED',
    'RETUNR RECEIVED',
    'WRONG RETURN RECEIVED',
    'CLAIM SHIPMENT',
    'COMPLETED',
    ' NO SERVICE'
];
export const startDate = new Date()?.setDate(new Date()?.getDate() - 6);
export const endDate = new Date();

export const shipRocketURL = `https://www.shiprocket.in/shipment-tracking/`;
export const pickerURL = `http://www.pickrr.com/tracking/#/?tracking_id=`;

export const COURIER_PARTNER = {
    PICKRR: 1,
    SHIPROCKET: 2
};
