/**
 * =========================================================================================
 * Component - Pagination
 * =========================================================================================
 **/

import React, { useEffect, useState } from 'react';

// Packages
import ReactPaginate from 'react-paginate'; /* React Paginate */
import Select from 'react-select'; /* React Select */

const PAGINATION = {
    CURRENT_PAGE: 1,
    LIMIT: 50
};

// Records per page
export const RECORDPERPAGE = [
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 250, label: '250' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' }
];

const getKeyName = (arrList, id) => {
    if (arrList && arrList.length) return arrList.find((_) => _.value == id);
};
const Pagination = (props) => {
    // State
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState({ value: 50, label: '50' });
    const [pageCount, setPageCount] = useState(1);

    useEffect(() => {
        setRecordsPerPage(getKeyName(RECORDPERPAGE, props?.limit || recordsPerPage?.value));
    }, [props?.limit]);

    useEffect(() => {
        setPageCount(Math.ceil(props.pageCount / recordsPerPage?.value));
    }, [recordsPerPage, props.pageCount]);

    useEffect(() => {
        setCurrentPage(props?.page?.currentPage || props?.page);
    }, [props?.page?.currentPage, props?.page]);

    const paginationClick = (data) => {
        props.onChange(data);
    };

    // Page click event
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        paginationClick({ limit: recordsPerPage?.value || PAGINATION.LIMIT, currentPage: selectedPage + 1 });
        if (props?.onPageChange) props?.onPageChange();
    };

    // Set Records per page
    const recordClick = (value) => {
        setRecordsPerPage(value);
        paginationClick({ limit: value?.value || PAGINATION.LIMIT, currentPage: 1 });
    };

    return (
        <div className="u_pagination" id="u_pagination">
            {/* Records per page */}
            <div className="u_pagination-record w_flex w_align-items-center">
                <span className="u_pagination-title u_mr-10">Entries</span>
                <div className="u_pagination-record-wpr">
                    <Select
                        classNamePrefix="u_record"
                        options={RECORDPERPAGE}
                        menuPlacement="top"
                        isSearchable={false}
                        value={recordsPerPage}
                        onChange={recordClick}
                    ></Select>
                </div>
            </div>

            {/* Pagination */}
            <ReactPaginate
                previousLabel={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z" />
                    </svg>
                }
                nextLabel={
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z" />
                    </svg>
                }
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={'w_flex'}
                pageLinkClassName={'u_pagination-link'}
                previousLinkClassName={'u_pagination-link u_pagination-link-previous'}
                breakLinkClassName={'u_pagination-link u_pagination-link-break'}
                nextLinkClassName={'u_pagination-link u_pagination-link-next'}
                disabledLinkClassName={'u_pagination-link-disabled'}
                activeLinkClassName={'u_pagination-link-active'}
                forcePage={currentPage ? currentPage - 1 : 1}
            />
        </div>
    );
};

export default Pagination;
